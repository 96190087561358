import React from "react";

import { Block } from "@/blocks/base";
import { BlockContainer } from "@/blocks/base/block-container";
import { RealDemo } from "@/blocks/shared/Demo/RealDemo";

import { Typography } from "@/uikit/Typography";

import { demoTextStyle } from "./styles.css";

const Demo = Block("blockDemo", (props) => {
  return (
    <BlockContainer settings={props.settings} width="full" defaultTheme="light" layout="demo">
      <Typography className={demoTextStyle}>Watch live demo</Typography>
      <RealDemo
        demoVideoUrl={props.demoVideoUrl}
        demoVideoBtn={props.demoVideoBtn}
        demoUrl={props.demoUrl}
        placeholder={props.heroImage}
      />
    </BlockContainer>
  );
});

export default Demo;
