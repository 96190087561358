"use client";

import { BlockContainer } from "@/blocks/base/block-container";
import { RemoteImage } from "@/uikit/remote-image";
import type { SanityImageObject } from "@sanity/image-url/lib/types/types";
import React, { useState, useRef, useEffect } from "react";
import { Loader } from "@/uikit/Loader";
import { Button } from "@/uikit/Button";
import PlayIcon from "@/svg/icons/play.svg";

import {
  styledDemo,
  styledAppContainer,
  styledApp,
  video,
  playButton,
  loaderStyle,
  demoImage,
  demoWrapperStyle,
  launchDemoButton,
  placeholderStyle,
  containerWrapperStyle,
} from "./demo.css";

import { ButtonSchema } from "@/schema";
import { getResolvedImage } from "@/core/sanityAPI/types";
import { trackEvent } from "@/core/analytics";

interface RealDemoProps {
  demoUrl: string;
  placeholder: SanityImageObject;
  demoVideoUrl: string;
  demoVideoBtn: ButtonSchema;
}

export const RealDemo = (props: RealDemoProps) => {
  const { demoVideoBtn, demoUrl, placeholder, demoVideoUrl } = props;
  const [isDemoActivated, setIsDemoActivated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlayVideo = async () => {
    setIsVideoActive(true);
    trackEvent("playDemoVideo");
  };

  const handleOnLoad = () => {
    setIsLoading(false);
    setIsLoaded(true);
  };

  const activate = () => {
    setIsLoading(true);
    setIsDemoActivated(true);
    trackEvent("launchDemo");
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.play();
    }
  }, [isVideoActive]);

  const hidePlaceHolder = isLoaded;

  return (
    <BlockContainer
      className={demoWrapperStyle}
      defaultTheme="light"
      layout="demo"
      blockPaddingTop="none"
      blockPaddingBottom="none"
    >
      <RemoteImage
        className={demoImage}
        image={props.placeholder}
        priority={true}
        quality={65}
        sizes={{
          mobile: "calc(100vw - 20px)",
          tablet: "calc(100vw - 20px)",
          desktop: "calc(100vw - 20px)",
        }}
      />
      <div className={styledDemo}>
        <div className={containerWrapperStyle}>
          <div className={styledAppContainer({ isDemo: isDemoActivated })}>
            {isDemoActivated && <iframe src={demoUrl} seamless={true} className={styledApp} onLoad={handleOnLoad} />}
            {!hidePlaceHolder && (
              <RemoteImage
                image={placeholder}
                priority={true}
                quality={65}
                className={placeholderStyle}
                sizes={{
                  mobile: "calc(100vw - 20px)",
                  tablet: "calc(100vw - 20px)",
                  desktop: "calc(100vw - 20px)",
                }}
              />
            )}
          </div>
        </div>
        {isVideoActive && <video className={video} ref={videoRef} src={demoVideoUrl} muted controls playsInline />}

        {isLoading && <Loader className={loaderStyle} />}
        {!isVideoActive && (
          <button className={playButton} aria-label="play demo video" onClick={handlePlayVideo}>
            <PlayIcon />
          </button>
        )}
        {!isDemoActivated && (
          <Button
            className={launchDemoButton}
            onClick={activate}
            aria-label="play demo video"
            key={demoVideoBtn.link.href}
            variant={demoVideoBtn.variant}
            size={demoVideoBtn.size}
            fontSize={demoVideoBtn.fontSize}
            fontWeight={demoVideoBtn.fontWeight}
            iconUrl={getResolvedImage(demoVideoBtn.icon)?.src}
          >
            {demoVideoBtn.text}
          </Button>
        )}
      </div>
    </BlockContainer>
  );
};
