import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fstyles%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA23S227CMAwA0He%2BwlKeQbk5Tc0Tleh%2F9JLuBoNdgU3798mdJjlR46cepXbsZHM6nG6Xs4bvFcB6%2FfdlCJSNNjqzFWoJ1DQvqY5ADYlDqidQ%2B2bftHupSKB84xu0UgPnrYOpeqkVgWqbaGqUGrmaHvSQaU2g9LykdpzXT3HK8vYEytWu85XUgdU79FnHI4GqXOxrLzXxdIw1tpY6LU7njjVxSL3n3nbtrm2lPizO4XFxDk9zNeyrrIsDgRoHDqlHApUwYcpO9swZkEPq6V87qWeeehjCmE3nhUDhiClk1V65WsCuyva%2BzZPsEEep74tz%2BOC7cM757DY%2FF9%2FOZfGdXQn0%2BSrlRhBz%2BSIwNiejCUwozBDYIpmxBNYX5ghsUcJ4Al%2F%2BiwRY1g0ERpcbqxkzinyWnGqCWJbtuLVQHrAn0BuXjtvVzy9J5bov9QMAAA%3D%3D%22%7D"
import "../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.1_@types+node@18.19.5_webpack@5.91.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fblocks%2Fshared%2FDemo%2FRealDemo%2Fdemo.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71X226rOBR971dsnVGlRAqRIVCK%2BzKfMa8OGOLGwYxxUnpG%2FfeRwYBxnDRzjjRSQxNflvdee3nZbHkWS%2FI3gn%2BeABrRMsVEjUFSThS70LcngL2QBZWBJAU7txhem%2B7t6etpaybiPS2FpP38XNSK1grDjx964gcr1AFDljzrXwfKqoPCECLU%2F1aiwZAM3zkt1fRDSVK3pZAnPHzlRNFVkKDnDejn%2Bm0RKdm3gp%2FVECnJj5UU57rAoMMlPKj0f1qrVc5kzikQBTmtFZUbkNWerKI420CWbiCMdhtAa29zuF4PRHRBeyCF%2BMCAmq7%2FhIl%2Beud4uOsznLgL75BuuBvJKljbcPKJYc9FfvwWOXoc2S3LtFItampB7nrIYVUMcdNBKzgrTOpJsoH5gbaRL%2FvwRUvnmsdQP6LYMGoANdQGorRHQ2srkriPpBS1Clr2k2IIkwG3b%2Fow%2BaQIWXOSx%2FUdRgOauFBZch3jgRUFrW00TEpFpU%2FzfmF%2Bw%2FlvbQVb9JzVlMhZ9OErKmhlNJ2EG9Cfl1gLXWNcN28ztIYBecr2pc%2FzRgo54fkqROhygAAuRK6CQHDx%2BdGE2RoCiFDT2VjpUAdSFKyugj7vEJnyja17oZQ4YQi1LnTHiXTBuHyM0MJ%2FXp3C2qz%2FDFhd0A5DNJGMZopteWR35DGhhHbeaWaCu96ZDlMl40pvmT0%2Fy1Xc0%2BEv7K5YoQ3ov3mIiUc0JGfqE9B21wIlLQ1YHYizslIgfQpm4DK7%2FbIrtLry36HvgeRdpY%2F%2B4ZjLcHw41nNzZ16bkC28cv1IHNOmCXLBhcQLCGpLtvxvFP3aNp5Wq3xMzL34oG1pMJ6zbHXgjWD6PLNGHZYYU23%2BvxzaS2WbRry8M7C7Ob73vYp2KiCcVTU2B7Y15OgH%2BPNEC0ZgZftFGO2abt2P95j30j1H2X3NgzMzbLGJE7OJrXG5GXclYWsMNWP8ZXALYe84pxT%2BYvy1mo4EX%2FGXNopGJHFW%2BtDwBlw%2BkFT1K0lNeTyQlnvcWeGUnHZD0%2Fu5Vaz8DKbDeJSM7uxVFDBFT%2B2yw5Dxapx8dom5xXfLmtun8ty3E2YCN11%2FlGVp5WtocyH0tcd4fW%2F0S9YtH%2Fhu9aOr1QqTXB9uD82W7uzDA6Jg7piHKmUBHF0AS8Y%2BZxjkLStWT7eHXTzU8OuGMcS7zDGGdJSyfTuZpeBeT9JoXGBCeDcITihRej8UdOVRo%2B2MgkwQuguRvqQOwuK9bJZ68uwqfRK0590G3X23%2BbpN3Zj5YPxeRx0j2O2Smzx6NPb1L6bB%2B6qxDgAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var appButton = 'l94raqi';
export var buttonStyle = 'l94raqf';
export var containerStyle = 'l94raq7';
export var containerWrapperStyle = 'l94raq3';
export var demoBtnText = 'l94raq4';
export var demoImage = 'l94raq2';
export var demoWrapperStyle = 'l94raq0';
export var launchDemoButton = 'l94raqh';
export var loaderStyle = 'l94raq8';
export var placeholderStyle = 'l94raqd';
export var playButton = 'l94raqg';
export var styledApp = 'l94raqc';
export var styledAppContainer = _7a468({defaultClassName:'l94raq5',variantClassNames:{isDemo:{true:'l94raq6'}},defaultVariants:{},compoundVariants:[]});
export var styledDemo = 'l94raq1';
export var styledPlaceholder = _7a468({defaultClassName:'l94raq9',variantClassNames:{hidden:{true:'l94raqa',false:'l94raqb'}},defaultVariants:{},compoundVariants:[]});
export var titleStyle = 'l94raqj';
export var video = 'l94raqe';
export var videoTitleStyle = 'l94raqk';